// material
import {
  AppBar,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  Fade,
  IconButton,
  Modal,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { JobData } from "../../../core/types/job.types";
import {
  locationBuilder,
  newLocationBuilder,
} from "../../../utils/jobLocationBuilder";
import parse from "html-react-parser";
import DeleteModal, { ApproveModal } from "./JobDelete";
import Iconify from "../../../components/Iconify";
import React, { Suspense, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RedoIcon from "@mui/icons-material/Redo";
import Backdrop from "@mui/material/Backdrop";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import {
  fetchJoinCategory,
  fetchJoinEmployment,
  fetchJoinLevels,
  fetchJoinOffices,
} from "../../../core/redux/features/join/joinSlice";
import {
  getJoinCategory,
  getJoinEmployment,
  getJoinLevels,
  getJoinoffices,
} from "../../../core/redux/features/join/joinThunks";

const JobFormCity = React.lazy(() => import("../Jobs/JobFormCity"));

// ----------------------------------------------------------------------

type Props = JobData & {
  newIndicator?: boolean;
};

export default function JobsCard(props: Props) {
  const [open, setOpen] = useState(false);
  return (
    <Card sx={{ height: "auto", marginBottom: "10px" }}>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={1.7}
        >
          <img
            src={props.company?.logo}
            alt=""
            style={{ width: "56px", height: "56px", borderRadius: "8px" }}
          />
          <Stack direction="row" spacing={1}>
            <ModalAddJobJoin />
            <IconButton>
              <CopyAll />
            </IconButton>
          </Stack>
        </Box>

        <Box pl={0.56} mb="18px">
          <Box pl={0.56} mb="18px" height={30}>
            <Typography
              fontSize="18px"
              fontWeight="700"
              color="#239F5E"
              textOverflow={"ellipsis"}
              overflow="clip"
              style={{
                display: "flex",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "100%",
              }}
            >
              {props.title}
            </Typography>
          </Box>
          <Typography fontSize="12px" fontWeight="700" color="#4B6657">
            {props.company?.name}
          </Typography>
        </Box>

        <Box pl={0.56} mb="18px" height={{ xs: 70, sm: 130, md: 142 }}>
          <Typography
            color="#4B6657"
            component={"span"}
            fontSize="14px"
            textOverflow={"ellipsis"}
            overflow="clip"
            style={{
              display: "flex",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "100%",
            }}
          >
            {parse(props.jobOverview)}
          </Typography>
        </Box>

        <Box display="flex" gap="35px" flexWrap="wrap" mb={3.5} pl="5px">
          <Box>
            <Typography fontSize="12px" fontWeight="600" color="#239F5E">
              Compensation
            </Typography>
            <Typography fontSize="11px" color=" #4B6657" fontWeight="500">
              {props.compensation.currency}
              {props.compensation.from} - {props.compensation.currency}
              {props.compensation.to} {props.compensation?.info}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="12px" fontWeight="600" color="#239F5E">
              Job Type
            </Typography>
            {Array.isArray(props.jobType) ? (
              props.jobType.map((item) => (
                <Typography fontSize="11px" color=" #4B6657" fontWeight="500">
                  {item.title}
                </Typography>
              ))
            ) : (
              <Typography fontSize="11px" color=" #4B6657" fontWeight="500">
                {props.jobType?.title}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography fontSize="12px" fontWeight="600" color="#239F5E">
              Location
            </Typography>
            <Typography fontSize="11px" color=" #4B6657" fontWeight="500">
              {props.newIndicator
                ? newLocationBuilder(props.locationObj)
                : `${
                    props.location?.country || props.country
                  }, ${locationBuilder({
                    state: props.state,
                    city: props.city,
                  })}`}
            </Typography>
          </Box>
        </Box>

        <Stack direction="row" spacing={{ md: 2, lg: 3 }} mb="14px">
          <DeleteModal
            slug={props.slug}
            id={props._id}
            pending={props.status === "pending"}
            rejected={props.status === "rejected"}
            newIndicator={props.newIndicator}
          />

          {props.status === "pending" ? (
            <ApproveModal slug={props.slug} id={props._id} body={props} />
          ) : (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:edit-fill" />}
              onClick={() => setOpen(!open)}
            >
              Edit
            </Button>
          )}

          <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(!open)}
            TransitionComponent={Transition}
            disableEnforceFocus={true}
            sx={{ zIndex: 1000 }}
          >
            <AppBar sx={{ position: "fixed" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen(!open)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ flex: 1, textAlign: "center" }}
                  variant="h6"
                  component="div"
                >
                  UPDATE JOB {props.title}
                </Typography>
              </Toolbar>
            </AppBar>
            <Suspense
              fallback={
                <Box
                  width="100%"
                  height="320px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress sx={{ color: "#0e2944" }} />
                </Box>
              }
            >
              <Box pb={4}>
                {open ? (
                  <JobFormCity
                    {...props}
                    setOpen={setOpen}
                    newForm={props.newIndicator || false}
                  />
                ) : null}
              </Box>
            </Suspense>
          </Dialog>
        </Stack>
      </Box>
    </Card>
  );
}

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

const ModalAddJobJoin = () => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);

  const joinCategory = useAppSelector(fetchJoinCategory);
  const joinEmployment = useAppSelector(fetchJoinEmployment);
  const joinLevels = useAppSelector(fetchJoinLevels);
  const joinOffices = useAppSelector(fetchJoinOffices);

  useEffect(() => {
    if (open) {
      joinCategory.statusFetch === "idle" && dispatch(getJoinCategory());
      joinEmployment.statusFetch === "idle" && dispatch(getJoinEmployment());
      joinLevels.statusFetch === "idle" && dispatch(getJoinLevels());
      joinOffices.statusFetch === "idle" && dispatch(getJoinoffices());
    }
  }, [open]);

  return (
    <>
      <IconButton
        color="success"
        sx={{ borderRadius: 2 }}
        onClick={() => setOpen(!open)}
      >
        <Typography fontSize="12px">Join</Typography>{" "}
        <RedoIcon fontSize="small" />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(!open)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Stack sx={style} className="hiddenScroll" position="relative">
            <AppBar position="sticky">
              <Stack
                bgcolor="#239f5e"
                p="10px 30px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" color="#fff">
                  Add a task to Join
                </Typography>
                <IconButton onClick={() => setOpen(!open)}>
                  <CloseFullscreenIcon
                    sx={{ color: "#fff" }}
                    fontSize="small"
                  />
                </IconButton>
              </Stack>
            </AppBar>

            <Stack p="10px 30px"></Stack>
          </Stack>
        </Fade>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  overflowY: "scroll",
  maxHeight: "calc(100vh - 10vh)",
};
