/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Iconify from "../../../components/Iconify";
import MenuItem from "@mui/material/MenuItem";
import { Typography, Box, Grid, CircularProgress, FormControl, InputLabel, Select, Stack, TextField, SelectChangeEvent } from "@mui/material";
// ------ React-Form
import { useForm } from "react-hook-form";

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import {
  createCompany,
  updateCompany,
} from "../../../core/redux/features/companies/companyThunks";
import RHFSelect from "../../../components/hook-form/RHFSelect";

import { Container } from "@mui/system";
import RichTextEditor from "../../../components/RichTextEditor";
import parse from "html-react-parser";
import { selectCompanyStatus } from "../../../core/redux/features/companies/companiesSlice";
import { fetchCountries } from "../../../core/redux/features/locations/locationThunks";
import { selectCountries } from "../../../core/redux/features/locations/locationsSlice";
import { CompanyData } from "../../../core/types/company.types";
import { v4 as uuidv4 } from "uuid";
import { request } from "../../../utils/Axios";
import axios from "axios";
import { uploadToCloudinary } from "../../../core/react-query/uploadToCloudinary.api";
import ImageDropZone from "../../../components/ImageDropZone";
import { uploadToSpaces } from "../../../utils/uploadToSpaces";
import { Delete } from "@mui/icons-material";
import { checkPrime } from "crypto";

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Formvalues {
  name: string;
  logo: string;
  companyUrl: string;
  country: string;
  ammount: number;
  currency: string;
  description: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  reddit: string;
  discord: string;
  whatsapp: string;
  telegram: string;
}
interface Props extends Partial<CompanyData> {
  isEdit: boolean;
}

interface ExtendedSocialLinks {
  name: string;
  value?: string;
  _id: string;
}

export default function FullScreenDialogCompany(props: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [description, setDescription] = React.useState(props.description || "");
  const [previewImages, setPreviewImages] = React.useState([]);

  const [socialMedia, setSocialMedia] = React.useState<ExtendedSocialLinks[]>([]);

  const countries = useAppSelector(selectCountries);

  const companyStatus = useAppSelector(selectCompanyStatus);

  const handleChangeCountry = (event: any) => {
    methods.setValue("country", event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && countries.data.length === 0) {
      dispatch(fetchCountries());
    }
  }, []);

  React.useEffect(() => {
    if (props.facebook) {
      setSocialMedia(prevState => [...prevState, { name: "facebook", value: props.facebook, _id: uuidv4() }]);
    }
    if (props.instagram) {
      setSocialMedia(prevState => [...prevState, { name: "instagram", value: props.instagram, _id: uuidv4() }]);
    }
    if (props.linkedin) {
      setSocialMedia(prevState => [...prevState, { name: "linkedin", value: props.linkedin, _id: uuidv4() }]);
    }
    if (props.tiktok) {
      setSocialMedia(prevState => [...prevState, { name: "tiktok", value: props.tiktok, _id: uuidv4() }]);
    }
    if (props.twitter) {
      setSocialMedia(prevState => [...prevState, { name: "twitter", value: props.twitter, _id: uuidv4() }]);
    }
    if (props.youtube) {
      setSocialMedia(prevState => [...prevState, { name: "youtube", value: props.youtube, _id: uuidv4() }]);
    }
    if (props.reddit) {
      setSocialMedia(prevState => [...prevState, { name: "reddit", value: props.reddit, _id: uuidv4() }]);
    }
    if (props.discord) {
      setSocialMedia(prevState => [...prevState, { name: "discord", value: props.discord, _id: uuidv4() }]);
    }
    if (props.whatsapp) {
      setSocialMedia(prevState => [...prevState, { name: "whatsapp", value: props.whatsapp, _id: uuidv4() }]);
    }
    if (props.telegram) {
      setSocialMedia(prevState => [...prevState, { name: "telegram", value: props.telegram, _id: uuidv4() }]);
    }

  }, [props.facebook, props.instagram, props.linkedin, props.tiktok, props.twitter, props.youtube]);

  React.useEffect(() => {
    if (socialMedia.length === 0) {
      setSocialMedia(prevState => [...prevState, { name: "", value: "", _id: uuidv4() }])
    }
  }, [socialMedia]);


  const handleLinkPlatformChange = (
    e: SelectChangeEvent<string>,
    id: string
  ) => {
    const newList = socialMedia.map((el) => {
      if (el._id === id) {
        return { ...el, name: e.target.value };
      }
      return el;
    });
    setSocialMedia(newList);
  };

  const handleLinkNameChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => {
    const newList = socialMedia.map((el) => {
      if (el._id === id) {
        return { ...el, value: e.target.value };
      }
      return el;
    });
    setSocialMedia(newList);
  };

  const handleClickAddLinkMore = () => {
    setSocialMedia([
      ...socialMedia,
      { name: "", value: "", _id: uuidv4() },
    ]);
  };

  const handleDeleteLinkField = (id: string) => {
    const filtered = socialMedia.filter((el) => el._id !== id);
    setSocialMedia(filtered);
  };


  //-------------------RHF-----------------//
  const CompanyFormSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    logo: Yup.mixed().required("required"),
    companyUrl: Yup.string().url("must be a valid URL").required("required"),
    country: Yup.string().required("required"),
    ammount: Yup.number().notRequired(),
    currency: Yup.string().notRequired(),
    facebook: Yup.string()
      .url("there must be a valid facebook URL")
      .notRequired(),
    twitter: Yup.string()
      .url("there must be a valid twitter URL")
      .notRequired(),
    linkedin: Yup.string()
      .url("there must be a valid linkedin URL")
      .notRequired(),
    instagram: Yup.string()
      .url("there must be a valid instagram URL")
      .notRequired(),
    youtube: Yup.string()
      .url("there must be a valid youtube URL")
      .notRequired(),
    tiktok: Yup.string().url("there must be a valid tiktok URL").notRequired(),
    discord: Yup.string().url("there must be a valid discord URL").notRequired(),
    whatsapp: Yup.string().url("there must be a valid whatsapp URL").notRequired(),
    telegram: Yup.string().url("there must be a valid telegram URL").notRequired(),
    reddit: Yup.string().url("there must be a valid reddit URL").notRequired(),
  });

  const methods = useForm<Formvalues>({
    resolver: yupResolver(CompanyFormSchema),
    defaultValues: {
      name: props.name || "",
      logo: props.logo || "",
      companyUrl: props.companyUrl || "",
      country: props.country || "",
      ammount: props.revenue?.ammount || 0,
      currency: props.revenue?.currency || "",
      facebook: props.facebook || "",
      twitter: props.twitter || "",
      linkedin: props.linkedin || "",
      instagram: props.instagram || "",
      youtube: props.youtube || "",
      tiktok: props.tiktok || "",
      discord: props.discord || "",
      whatsapp: props.whatsapp || "",
      telegram: props.telegram || "",
      reddit: props.reddit || "",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const uploadHandler = async () => {
  //   try {
  //     const formData = new FormData();
  //     const logoUrl = methods.getValues("logo");
  //     const response = await axios.get(logoUrl, {
  //       responseType: "arraybuffer",
  //     });
  //     const blob = new Blob([response.data], {
  //       type: response.headers["content-type"],
  //     });

  //     const file = new File([blob], "downloaded-image.jpg", {
  //       type: "image/jpeg",
  //     });

  //     formData.append("file", file);

  //     const cloudinaryImageUrl = await uploadToCloudinary(formData);

  //     return cloudinaryImageUrl.secure_url;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDrop = (acceptedFiles: any) => {
    console.log(acceptedFiles);
  };

  const onSubmit = async (data: Formvalues) => {
    // const cloudinaryUrl = await uploadHandler();
    const formData = new FormData();
    formData.append("file", previewImages[0]);
    if (props.isEdit && props._id) {
      if (previewImages.length === 0) {
        dispatch(
          updateCompany({
            id: props._id,
            body: {
              ...data,
              description,
              facebook: socialMedia.filter((social) => social.name === "facebook").map((social) => social.value).join(','),
              instagram: socialMedia.filter((social) => social.name === "instagram").map((social) => social.value).join(','),
              youtube: socialMedia.filter((social) => social.name === "youtube").map((social) => social.value).join(','),
              linkedin: socialMedia.filter((social) => social.name === "linkedin").map((social) => social.value).join(','),
              tiktok: socialMedia.filter((social) => social.name === "tiktok").map((social) => social.value).join(','),
              twitter: socialMedia.filter((social) => social.name === "twitter").map((social) => social.value).join(','),
              discord: socialMedia.filter((social) => social.name === "discord").map((social) => social.value).join(','),
              whatsapp: socialMedia.filter((social) => social.name === "whatsapp").map((social) => social.value).join(','),
              telegram: socialMedia.filter((social) => social.name === "telegram").map((social) => social.value).join(','),
              reddit: socialMedia.filter((social) => social.name === "reddit").map((social) => social.value).join(','),
              revenue: { ammount: data.ammount, currency: data.currency },
            },
          })
        );
      }
      else {
        const spacesImageUrl = await uploadToSpaces(formData);
        if (spacesImageUrl) {
          if (props.isEdit && props._id) {
            dispatch(
              updateCompany({
                id: props._id,
                body: {
                  ...data,
                  description,
                  facebook: socialMedia.filter((social) => social.name === "facebook").map((social) => social.value).join(','),
                  instagram: socialMedia.filter((social) => social.name === "instagram").map((social) => social.value).join(','),
                  youtube: socialMedia.filter((social) => social.name === "youtube").map((social) => social.value).join(','),
                  linkedin: socialMedia.filter((social) => social.name === "linkedin").map((social) => social.value).join(','),
                  tiktok: socialMedia.filter((social) => social.name === "tiktok").map((social) => social.value).join(','),
                  twitter: socialMedia.filter((social) => social.name === "twitter").map((social) => social.value).join(','),
                  discord: socialMedia.filter((social) => social.name === "discord").map((social) => social.value).join(','),
                  whatsapp: socialMedia.filter((social) => social.name === "whatsapp").map((social) => social.value).join(','),
                  telegram: socialMedia.filter((social) => social.name === "telegram").map((social) => social.value).join(','),
                  reddit: socialMedia.filter((social) => social.name === "reddit").map((social) => social.value).join(','),
                  logo: spacesImageUrl,
                  revenue: { ammount: data.ammount, currency: data.currency },
                },
              })
            );
          } else {
            dispatch(
              createCompany({
                ...data,
                facebook: socialMedia.filter((social) => social.name === "facebook").map((social) => social.value).join(','),
                instagram: socialMedia.filter((social) => social.name === "instagram").map((social) => social.value).join(','),
                youtube: socialMedia.filter((social) => social.name === "youtube").map((social) => social.value).join(','),
                linkedin: socialMedia.filter((social) => social.name === "linkedin").map((social) => social.value).join(','),
                tiktok: socialMedia.filter((social) => social.name === "tiktok").map((social) => social.value).join(','),
                twitter: socialMedia.filter((social) => social.name === "twitter").map((social) => social.value).join(','),
                discord: socialMedia.filter((social) => social.name === "discord").map((social) => social.value).join(','),
                whatsapp: socialMedia.filter((social) => social.name === "whatsapp").map((social) => social.value).join(','),
                telegram: socialMedia.filter((social) => social.name === "telegram").map((social) => social.value).join(','),
                reddit: socialMedia.filter((social) => social.name === "reddit").map((social) => social.value).join(','),
                description,
                logo: spacesImageUrl,
                revenue: { ammount: data.ammount, currency: data.currency },
              })
            );
          }
        }
      }
    }
    else {
      const spacesImageUrl = await uploadToSpaces(formData);
      if (spacesImageUrl) {
        dispatch(
          createCompany({
            ...data,
            description,
            facebook: socialMedia.filter((social) => social.name === "facebook").map((social) => social.value).join(','),
            instagram: socialMedia.filter((social) => social.name === "instagram").map((social) => social.value).join(','),
            youtube: socialMedia.filter((social) => social.name === "youtube").map((social) => social.value).join(','),
            linkedin: socialMedia.filter((social) => social.name === "linkedin").map((social) => social.value).join(','),
            tiktok: socialMedia.filter((social) => social.name === "tiktok").map((social) => social.value).join(','),
            twitter: socialMedia.filter((social) => social.name === "twitter").map((social) => social.value).join(','),
            discord: socialMedia.filter((social) => social.name === "discord").map((social) => social.value).join(','),
            whatsapp: socialMedia.filter((social) => social.name === "whatsapp").map((social) => social.value).join(','),
            telegram: socialMedia.filter((social) => social.name === "telegram").map((social) => social.value).join(','),
            reddit: socialMedia.filter((social) => social.name === "reddit").map((social) => social.value).join(','),
            logo: spacesImageUrl,
            revenue: { ammount: data.ammount, currency: data.currency },
          })
        );
      }
    }

  };

  //-------------------------------------//

  return (
    <>
      {props.isEdit ? (
        <Button
          startIcon={<Iconify icon="eva:edit-fill" width={24} height={24} />}
          variant="contained"
          onClick={handleClickOpen}
        >
          UPDATE COMPANY
        </Button>
      ) : (
        <Button
          startIcon={<Iconify icon="eva:plus-fill" />}
          variant="contained"
          onClick={handleClickOpen}
        >
          NEW COMPANY
        </Button>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEnforceFocus={true}
        sx={{ zIndex: 1000 }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h6"
            >
              Sound
            </Typography> */}
          </Toolbar>
        </AppBar>
        <Box mt={2.8} mb={3.5}>
          <Typography fontSize="23px" fontWeight="600" textAlign="center">
            {props.isEdit ? "Update Company" : "Create a new Company"}
          </Typography>
        </Box>
        <Box sx={BoxStyle}>
          <Container>
            <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <Drag icon="line-md:uploading-loop" />
                </Grid> */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      border: "1px solid gray",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px"
                    }}
                  >
                    {props.logo ?
                      <>
                        <img
                          src={props.logo}
                          alt={props.name + " logo"}
                          width={180}
                          height={180}
                        />
                        <ImageDropZone
                          onDrop={handleDrop}
                          previewImages={previewImages.length !== 0 ? previewImages : props.logo}
                          setPreviewImages={setPreviewImages}
                        />
                      </> :
                      <ImageDropZone
                        onDrop={handleDrop}
                        previewImages={previewImages}
                        setPreviewImages={setPreviewImages}
                      />}

                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField name="name" label="Company name" />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <RHFTextField name="logo" label="Logo URL" />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <RHFTextField name="companyUrl" label="Company URL" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFSelect
                    name="country"
                    onChange={handleChangeCountry}
                    label="Country"
                  >
                    {countries.data.map((country: any) => (
                      <MenuItem key={uuidv4()} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField name="ammount" type="number" label="Revenue" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFSelect name="currency" label="Currency">
                    {currencies.map((c) => (
                      <MenuItem value={c.label} key={uuidv4()}>
                        {c.label} {c.value}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid item xs={12} md={12} >
                  <Typography color="#6E917E" fontWeight="600" fontSize="16px">
                    Edit Social Links
                  </Typography>
                  <Stack direction="row" justifyContent="space-between" marginTop={1} gap="35px">
                    <FormControl sx={{ maxWidth: "50%" }} fullWidth>
                      <InputLabel style={{ marginTop: 2 }} id={`platform-label-${socialMedia[0]?.name}`}>Platform</InputLabel>
                      <Select
                        value={socialMedia[0]?.name}
                        labelId={`social-platform-label-${socialMedia[0]?.name}`}
                        onChange={(e) => handleLinkPlatformChange(e, socialMedia[0]?._id)}
                        id={`socialPlatform-${socialMedia[0]?.name}`}
                        label="Platform"
                        variant="standard"
                      >
                        <MenuItem value="linkedin">LinkedIn</MenuItem>
                        <MenuItem value="youtube">YouTube</MenuItem>
                        <MenuItem value="facebook">Facebook</MenuItem>
                        <MenuItem value="instagram">Instagram</MenuItem>
                        <MenuItem value="twitter">Twitter</MenuItem>
                        <MenuItem value="tiktok">Tiktok</MenuItem>
                        <MenuItem value="reddit">Reddit</MenuItem>
                        <MenuItem value="discord">Discord</MenuItem>
                        <MenuItem value="whatsapp">WhatsApp</MenuItem>
                        <MenuItem value="telegram">Telegram</MenuItem>
                      </Select>
                    </FormControl>
                    <Stack width="100%" justifyContent="flex-end">
                      <TextField
                        variant="standard"
                        value={socialMedia[0]?.value}
                        onChange={(e) => handleLinkNameChange(e, socialMedia[0]?._id)}
                        label="Link"
                        type="url"
                      />
                    </Stack>
                    <IconButton
                      onClick={() => handleDeleteLinkField(socialMedia[0]?._id)}
                      color="error"
                      sx={{ maxWidth: 50, justifyContent: "flex-start" }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </Stack>
                  {socialMedia.slice(1).map((socialLink, index) => {
                    if (socialLink._id !== "") {
                      return (
                        <Stack key={index} direction="row" justifyContent="space-between" marginTop={1} gap="35px">
                          <FormControl sx={{ maxWidth: "50%" }} fullWidth>
                            <InputLabel style={{ marginTop: 2 }} id={`platform-label-${index}`}>Platform</InputLabel>
                            <Select
                              value={socialLink?.name}
                              onChange={(e) => handleLinkPlatformChange(e, socialLink._id)}
                              labelId={`social-platform-label-${index}`}
                              id={`socialPlatform-${index}`}
                              label="Platform"
                              variant="standard"
                            >
                              <MenuItem value="linkedin">LinkedIn</MenuItem>
                              <MenuItem value="youtube">YouTube</MenuItem>
                              <MenuItem value="facebook">Facebook</MenuItem>
                              <MenuItem value="instagram">Instagram</MenuItem>
                              <MenuItem value="twitter">Twitter</MenuItem>
                              <MenuItem value="tiktok">Tiktok</MenuItem>
                              <MenuItem value="reddit">Reddit</MenuItem>
                              <MenuItem value="discord">Discord</MenuItem>
                              <MenuItem value="whatsapp">WhatsApp</MenuItem>
                              <MenuItem value="telegram">Telegram</MenuItem>
                            </Select>
                          </FormControl>
                          <Stack width="100%" justifyContent="flex-end">
                            <TextField
                              variant="standard"
                              value={socialLink.value}
                              onChange={(e) => handleLinkNameChange(e, socialLink._id)}
                              label="Link"
                              type="url"
                            />
                          </Stack>
                          <IconButton
                            onClick={() => handleDeleteLinkField(socialLink._id)}
                            color="error"
                            sx={{ maxWidth: 50, justifyContent: "flex-start" }}
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Stack>
                      );
                    } else {
                      return null;
                    }
                  })}

                  <Button
                    onClick={handleClickAddLinkMore}
                    sx={{ maxWidth: 150, justifyContent: "flex-start" }}
                  >
                    <Typography variant="h14" fontWeight="600">
                      Add More +
                    </Typography>
                  </Button>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <RHFTextField name="facebook" label="Facebook url" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTextField name="twitter" label="Twitter url" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTextField name="linkedin" label="Linkedin url" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTextField name="instagram" label="Instagram url" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTextField name="youtube" label="Youtube url" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTextField name="tiktok" label="Tiktok url" />
                </Grid> */}

                <Grid item xs={12}>
                  <Typography>Description</Typography>
                  <RichTextEditor
                    value={description}
                    setValue={setDescription}
                  />
                  <Typography component="span">{parse(description)}</Typography>
                </Grid>

                <Grid item xs={12}>
                  {JSON.stringify(methods.formState.errors)}
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || companyStatus === "loading" || props.logo ? false : previewImages.length === 0 ? true : false}
                    fullWidth
                    sx={{ height: "50px" }}
                  >
                    {companyStatus === "loading" ? (
                      <CircularProgress />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Container>
        </Box>
      </Dialog >
    </>
  );
}

export const BoxStyle: any = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0px auto",
};

export const PhoneStyle = {
  padding: "10px",
  outline: "none",
  backgroundColor: "rgb(239, 246, 243)",
};

export const StyleForm: any = {
  display: "flex",
  flexDirection: "column",
  gap: 25,
};

export const StyleInput: any = {
  width: "570px",
};

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "GBP",
    label: "£",
  },
];
