import { createSlice } from "@reduxjs/toolkit";
import { JobData } from "../../../types/job.types";
import { RootState } from "../../store";
import {
  createJobCites,
  createOptimizedJobs,
  deleteJobById,
  deleteNewJobById,
  deletePendingJobById,
  fetchAllJobs,
  fetchDynamicJobs,
  fetchJobsByCompany,
  fetchNewJobs,
  fetchPendingJobs,
  fetchPendingJobsByStatus,
  updateJobById,
  updateJobInstancesById,
} from "./jobThunks";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
} from "../../../../components/CustomToastify";

interface InitState {
  status: "idle" | "loading" | "success" | "fail";
  allJobsCount: number;
  activeJobsCount: number;
  activeDynamicJobsCount: number;
  activeStatusJobsCount: number;

  jobCategories: Array<{
    _id: string;
    numberOfJobs: number;
    title: string;
  }>;
  active: Array<JobData>;
  pendingJobs: Array<JobData>;
  dynamicJobs: Array<JobData>;
  pendingJobsByStatus: Array<JobData>;
  companyJobs: Array<JobData>;
  newJobs: Array<JobData>;
}

const initState: InitState = {
  status: "idle",
  allJobsCount: 0,
  activeJobsCount: 0,
  activeDynamicJobsCount: 0,
  activeStatusJobsCount: 0,
  active: [],
  pendingJobs: [],
  dynamicJobs: [],
  pendingJobsByStatus: [],
  companyJobs: [],
  jobCategories: [],
  newJobs: [],
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createOptimizedJobs.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(createOptimizedJobs.fulfilled, (state, action) => {
        state.newJobs = [...state.newJobs, action.payload.body];
        state.active = [...state.active, action.payload.body];
        state.status = "success";
        toastSuccess("Dynamic Job created successfully");
      })

      .addCase(createOptimizedJobs.rejected, (state, action) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchAllJobs.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(fetchAllJobs.fulfilled, (state, action) => {
        state.active = [...state.active, ...action.payload.body.jobs];
        state.allJobsCount = action.payload.body.count;
        state.activeJobsCount = action.payload.body.activeCount;
        state.status = "success";
      })

      .addCase(fetchAllJobs.rejected, (state, action) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchDynamicJobs.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(fetchDynamicJobs.fulfilled, (state, action) => {
        state.dynamicJobs = [...state.dynamicJobs, ...action.payload.body.jobs];
        state.allJobsCount = action.payload.body.count;
        state.activeDynamicJobsCount = action.payload.body.activeCount;
        state.status = "success";
      })

      .addCase(fetchDynamicJobs.rejected, (state, action) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchPendingJobs.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(fetchPendingJobs.fulfilled, (state, action) => {
        state.pendingJobs = [...action.payload.body.jobs];
        state.allJobsCount = action.payload.body.count;
        state.status = "success";
      })

      .addCase(fetchPendingJobs.rejected, (state, action) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchPendingJobsByStatus.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(fetchPendingJobsByStatus.fulfilled, (state, action) => {
        state.pendingJobsByStatus = [...action.payload.body.jobs];
        state.allJobsCount = action.payload.body.count;
        state.activeStatusJobsCount = action.payload.body.activeCount;
        state.status = "success";
      })

      .addCase(fetchPendingJobsByStatus.rejected, (state, action) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchJobsByCompany.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(fetchJobsByCompany.fulfilled, (state, action) => {
        state.companyJobs = [...action.payload.body];
        state.status = "success";
      })

      .addCase(fetchJobsByCompany.rejected, (state, action) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchNewJobs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchNewJobs.fulfilled, (state, action) => {
        state.newJobs = action.payload.body;
        state.status = "success";
      })
      .addCase(fetchNewJobs.rejected, (state, action) => {
        state.status = "fail";
      });

    builder
      .addCase(createJobCites.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(createJobCites.fulfilled, (state, action) => {
        state.status = "success";
        toastSuccess("Success! New jobs will appear on Job Center page soon.");
      })

      .addCase(createJobCites.rejected, (state, action) => {
        state.status = "fail";
        toast("Something went wrong");
      });

    // --------------------- update job ---------------------
    builder
      .addCase(updateJobById.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(updateJobById.fulfilled, (state, action) => {
        state.status = "success";
        state.active = state.active.map((item: any) => {
          if (item._id === action.payload.body._id) {
            item = action.payload.body;
          }
          return item;
        });
        toastSuccess("Job updated");
      })

      .addCase(updateJobById.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong");
      });
    // --------------------------------------------------

    // --------------------- update job instances ---------------------
    builder
      .addCase(updateJobInstancesById.pending, (state) => {
        state.status = "loading";
      })

      .addCase(updateJobInstancesById.fulfilled, (state, action) => {
        state.status = "success";
        state.companyJobs = state.companyJobs.map((item: any) => {
          const matchingItem = action.payload.body.find(
            (payloadItem: any) => payloadItem._id === item._id
          );

          if (matchingItem) {
            return matchingItem;
          }

          return item;
        });

        toastSuccess("Job updated");
      })

      .addCase(updateJobInstancesById.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong");
      });
    // --------------------------------------------------

    // --------------------- delete job ---------------------
    builder
      .addCase(deleteJobById.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteJobById.fulfilled, (state, action) => {
        state.status = "success";
        state.active = state.active.filter(
          (job) => job._id !== action.payload.body
        );

        toastSuccess("Job deleted");
      })

      .addCase(deleteJobById.rejected, (state) => {
        state.status = "fail";
        toastError("Something went wrong");
      });

    builder
      .addCase(deleteNewJobById.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteNewJobById.fulfilled, (state, action) => {
        state.status = "success";
        state.active = state.active.filter(
          (job) => job._id !== action.payload.body
        );
        state.dynamicJobs = state.dynamicJobs.filter(
          (job) => job._id !== action.payload.body
        );
        toastSuccess("Job deleted");
      })

      .addCase(deleteNewJobById.rejected, (state) => {
        state.status = "fail";
        toastError("Something went wrong");
      });

    builder
      .addCase(deletePendingJobById.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deletePendingJobById.fulfilled, (state, action) => {
        state.status = "success";
        state.pendingJobs = state.pendingJobs.filter(
          (job) => job._id !== action.payload.body
        );
        toastSuccess("Job deleted");
      })

      .addCase(deletePendingJobById.rejected, (state) => {
        state.status = "fail";
        toastError("Something went wrong");
      });
  },
});

export default jobsSlice.reducer;
export const selectJobStatus = (state: RootState) => state.jobs.status;
export const selectNewJobs = (state: RootState) => state.jobs.newJobs;
export const selectDynamicJobs = (state: RootState) => state.jobs.dynamicJobs;
export const selectActiveJobs = (state: RootState) => state.jobs.active;
export const selectPendingJobs = (state: RootState) => state.jobs.pendingJobs;
export const selectPendingJobsByStatus = (state: RootState) =>
  state.jobs.pendingJobsByStatus;
export const selectCompanyJobs = (state: RootState) => state.jobs.companyJobs;
export const selectActiveJobsCount = (state: RootState) =>
  state.jobs.activeJobsCount;
export const selectActiveDynamicJobsCount = (state: RootState) =>
  state.jobs.activeDynamicJobsCount;
export const selectActiveStatusJobsCount = (state: RootState) =>
  state.jobs.activeStatusJobsCount;
