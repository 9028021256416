import { useState, useEffect, useMemo } from "react";
import {
  Box,
  CardHeader,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { StatByJobTypeObject } from "../../../core/types/stats.types";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import { fetchViewStatsByCountryAndJobType } from "../../../core/redux/features/stats/statsThunks";
import { fetchAllCategories } from "../../../core/redux/features/minor/minorThunks";
import { selectCategory } from "../../../core/redux/features/minor/minorSlice";
import { request } from "../../../utils/Axios";

export const CompanyStatsDatesChart = ({
  title,
  tagName,
  companyId,
}: {
  tagName: string;
  title: string;
  companyId: string;
}) => {
  const [companyStats, setCompanyStats] = useState<
    { _id: string; count: number }[]
  >([]);
  const [chartLabelsState, setChartLabelsState] = useState<string[]>();
  const [chartDataState, setChartDataState] =
    useState<{ name: string; data: number[] }[]>();
  const [flatStats, setFlatStats] = useState<
    {
      _id: string;
      count: number;
    }[]
  >([]);

  const fetchStats = async () => {
    if (companyId) {
      const res = await request.get(`api/v1/company/stats/dates/${companyId}`);
      setCompanyStats(res.data.body.dates);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [companyId]);

  useEffect(() => {
    let newArray = [...companyStats];
    const usaIndex = newArray.findIndex((user) => user._id === "USA");
    const unitedStatesIndex = newArray.findIndex(
      (user) => user._id === "United States"
    );

    // If both "USA" and "United States" are found, combine them
    if (usaIndex !== -1 && unitedStatesIndex !== -1) {
      newArray[usaIndex].count += newArray[unitedStatesIndex].count;
      newArray.splice(unitedStatesIndex, 1);
    }

    setFlatStats(newArray);
  }, [companyStats]);

  useEffect(() => {
    setChartLabelsState(flatStats.map((i) => i._id));
    setChartDataState([
      {
        name: tagName,
        data: flatStats.map((i) => i.count),
      },
    ]);
  }, [flatStats, tagName]);

  return (
    <Box component={Paper} p="20px 5px">
      {chartDataState && (
        <ReactApexChart
          type="line"
          options={{
            dataLabels: { enabled: false },
            title: { text: title },
            xaxis: { categories: chartLabelsState },
          }}
          series={chartDataState}
          height={450}
        />
      )}
    </Box>
  );
};

export const CompanyStatsCountyChart = ({
  title,
  companyId,
}: {
  title: string;
  companyId: string;
}) => {
  const [companyStats, setCompanyStats] = useState<
    { _id: string; count: number }[]
  >([]);
  const [chartDataState, setChartDataState] =
    useState<{ x: string; y: number }[]>();

  const formatetChartData = (data: Array<{ _id: string; count: number }>) => {
    return data.map((i) => {
      if (i._id === "unknown") {
        return { x: `Unknown`, y: i.count };
      }
      return { x: i._id, y: i.count };
    });
  };

  const fetchStats = async () => {
    if (companyId) {
      const res = await request.get(`api/v1/company/stats/events/${companyId}`);
      setCompanyStats(res.data.body.events);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [companyId]);

  useEffect(() => {
    let newArray = [...companyStats];
    const usaIndex = newArray.findIndex((user) => user._id === "USA");
    const unitedStatesIndex = newArray.findIndex(
      (user) => user._id === "United States"
    );

    // If both "USA" and "United States" are found, combine them
    if (usaIndex !== -1 && unitedStatesIndex !== -1) {
      newArray[usaIndex].count += newArray[unitedStatesIndex].count;
      newArray.splice(unitedStatesIndex, 1);
    }
    setChartDataState(formatetChartData(newArray));
  }, [companyStats]);

  return (
    <Box component={Paper} p="20px 5px">
      {chartDataState?.length && (
        <ReactApexChart
          type="treemap"
          options={{
            legend: { show: false },
            title: { text: title },
            dataLabels: {
              enabled: true, // Enable data labels to show values
            },
            plotOptions: {
              treemap: {
                enableShades: true,
                shadeIntensity: 0.1,
                reverseNegativeShade: true,
                distributed: true,
              },
            },
          }}
          series={[{ data: chartDataState }]}
          height={550}
        />
      )}
    </Box>
  );
};

export const CompanyStatsJobClicksByCountyChart = ({
  companyId,
  title,
}: {
  title: string;
  companyId: string;
}) => {
  const [companyStats, setCompanyStats] = useState<
    { _id: string; country: string; count: number }[]
  >([]);

  const fetchStats = async () => {
    if (companyId) {
      const res = await request.get(
        `api/v1/company/stats/applications/${companyId}`
      );
      setCompanyStats(res.data.body.applicationsByCountry);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [companyId]);

  const chartData = useMemo(() => {
    return {
      series: [
        {
          data: companyStats.map((item) => item.count),
        },
      ],
      options: {
        title: { text: title },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: companyStats.map((item) => item.country),
          // type: "category",
          labels: {
            show: true,
          },
        },
      },
    };
  }, [companyStats]);

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={"500%"}
      />
    </div>
  );
};

export const CompanyStatsForSpecificCategoryChart = ({
  companyId,
  title,
}: {
  companyId: string;
  title: string;
}) => {
  const dispatch = useAppDispatch();
  const [companyStats, setCompanyStats] = useState<StatByJobTypeObject[]>([]);

  const fetchStats = async () => {
    if (companyId) {
      const res = await request.get(
        `api/v1/company/stats/category-applications/${companyId}`
      );
      setCompanyStats(res.data.body.applicationsForSpecificCategoryByCountry);
    }
  };

  const [series, setSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  // const groupedData: { [key: string]: { [key: string]: number } } = {};
  const [projection, setProjection] = useState({
    timePeriod: "7-days",
    category: "62490bfff9c26249edf80664",
  });
  const allCategories = useAppSelector(selectCategory);

  const options = {
    chart: {
      type: "bar",
      // height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
      },
    },
    xaxis: {
      type: "category",
      categories: categories, // Add your categories (e.g., country names) here
      labels: {
        show: true,
      },
    },
    legend: {
      position: "top",
    },
    fill: { type: series?.map((i: any) => i.fill) },
    responsive: [
      {
        breakpoint: 600,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  } as ApexOptions;

  const handleProjection = (
    e: SelectChangeEvent<string>,
    projection: string
  ) => {
    switch (projection) {
      case "time":
        setProjection((prev) => {
          return { ...prev, timePeriod: e.target.value };
        });
        break;

      case "category":
        setProjection((prev) => {
          return { ...prev, category: e.target.value };
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    fetchStats();
  }, [companyId]);

  useEffect(() => {
    const allCountries = Array.from(
      new Set(
        companyStats.flatMap((job) => job.data.map((item) => item.country))
      )
    ).sort();

    const formattedChartData = companyStats
      .map((job) => {
        // const data: number[] = allCountries.map((country) => {
        //   const countryData = job.data.find((item) => item.country === country);
        //   return countryData ? countryData.count : 0;
        // });

        // return {
        //   jobType: job.jobtype,
        //   ...data,
        // };
        const countryCounts: Record<string, number> = {};

        allCountries.forEach((country) => {
          const countryData = job.data.find((item) => item.country === country);
          countryCounts[country] = countryData ? countryData.count : 0;
        });

        return {
          jobType: job.jobtype,
          data: allCountries.map((country) => countryCounts[country]),
        };
      })
      .map((item) => ({
        name: item.jobType,
        data: item.data, // Extract counts, skipping jobType
      }));

    setSeries(formattedChartData);
    setCategories(allCountries);
  }, [companyStats]);

  useEffect(() => {
    dispatch(fetchViewStatsByCountryAndJobType(projection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projection]);

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [projection]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <CardHeader title={"Views by country and type of job"} />
        <Stack direction="row">
          <Box sx={{ padding: "12px" }}>
            <Select
              value={projection.timePeriod}
              onChange={(e) => handleProjection(e, "time")}
            >
              <MenuItem value={"all"}>In months</MenuItem>
              <MenuItem value={"90-days"}>last 90 days</MenuItem>
              <MenuItem value={"30-days"}>last 30 days</MenuItem>
              <MenuItem value={"14-days"}>last 14 days</MenuItem>
              <MenuItem value={"7-days"}>last 7 days</MenuItem>
            </Select>
          </Box>

          <Box sx={{ padding: "12px" }}>
            <Select
              value={projection.category}
              onChange={(e) => handleProjection(e, "category")}
            >
              {allCategories.data.map((category) => (
                <MenuItem value={category._id}>{category.title}</MenuItem>
              ))}
            </Select>
          </Box>
        </Stack>
      </Stack>
      {series && (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={"500%"}
        />
      )}
    </Box>
  );
};

export const ApexChart = ({
  stats,
  title,
}: {
  stats: StatByJobTypeObject[];
  title: string;
}) => {
  const dispatch = useAppDispatch();
  const [series, setSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const groupedData: { [key: string]: { [key: string]: number } } = {};
  const [projection, setProjection] = useState({
    timePeriod: "7-days",
    category: "62490bfff9c26249edf80664",
  });
  const allCategories = useAppSelector(selectCategory);

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      type: "category",
      categories: categories, // Add your categories (e.g., country names) here
      labels: {
        show: true,
      },
    },
    legend: {
      position: "top",
    },
    fill: { type: series?.map((i: any) => i.fill) },
    responsive: [
      {
        breakpoint: 600,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  } as ApexOptions;

  const handleProjection = (
    e: SelectChangeEvent<string>,
    projection: string
  ) => {
    switch (projection) {
      case "time":
        setProjection((prev) => {
          return { ...prev, timePeriod: e.target.value };
        });
        break;

      case "category":
        setProjection((prev) => {
          return { ...prev, category: e.target.value };
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const allCountries = Array.from(
      new Set(stats.flatMap((job) => job.data.map((item) => item.country)))
    ).sort();

    const formattedChartData = stats
      .map((job) => {
        const countryCounts: Record<string, number> = {};

        allCountries.forEach((country) => {
          const countryData = job.data.find((item) => item.country === country);
          countryCounts[country] = countryData ? countryData.count : 0;
        });

        return {
          jobType: job.jobtype,
          data: allCountries.map((country) => countryCounts[country]),
        };
      })
      .map((item) => ({
        name: item.jobType,
        data: item.data, // Extract counts, skipping jobType
      }));

    setSeries(formattedChartData);
    setCategories(allCountries);
  }, [stats]);

  useEffect(() => {
    dispatch(fetchViewStatsByCountryAndJobType(projection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projection]);

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [projection]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <CardHeader title={"Views by country and type of job"} />
        <Stack direction="row">
          <Box sx={{ padding: "12px" }}>
            <Select
              value={projection.timePeriod}
              onChange={(e) => handleProjection(e, "time")}
            >
              <MenuItem value={"all"}>In months</MenuItem>
              <MenuItem value={"90-days"}>last 90 days</MenuItem>
              <MenuItem value={"30-days"}>last 30 days</MenuItem>
              <MenuItem value={"14-days"}>last 14 days</MenuItem>
              <MenuItem value={"7-days"}>last 7 days</MenuItem>
            </Select>
          </Box>

          <Box sx={{ padding: "12px" }}>
            <Select
              value={projection.category}
              onChange={(e) => handleProjection(e, "category")}
            >
              {allCategories.data.map((category) => (
                <MenuItem value={category._id}>{category.title}</MenuItem>
              ))}
            </Select>
          </Box>
        </Stack>
      </Stack>
      {series && (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={"500%"}
        />
      )}
    </Box>
  );
};
