export const locationBuilder = (location: {
  state: string | undefined;
  city: string | undefined;
}) => {
  if (location.state && location.city) {
    return `${location.city}, ${location.state}`;
  } else if (location.city && !location.state) {
    return `${location.city}`;
  }
};

export const newLocationBuilder = ({
  country,
  state,
}: {
  country: string;
  state: string;
}) => {
  let locationString = "";

  if (country === "all") {
    locationString = "Global";
  }

  if (country !== "all" && (state === "all" || state === "")) {
    locationString = country;
  }

  if (country !== "all" && state !== "all" && state !== "") {
    locationString = `${country}, ${state}`;
  }

  return locationString;
};
