import { Box } from "@mui/material";
import { JobData } from "../core/types/job.types";
import { JobList } from "../sections/@dashboard/products";

type Props = {
  jobs: JobData[];
};

const ViewCompanyJobs = (props: Props) => {
  return (
    <Box>
      <JobList jobs={props.jobs} />
    </Box>
  );
};

export default ViewCompanyJobs;
