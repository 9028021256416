import {
  Container,
  Stack,
  Typography,
  Box,
  Button,
  CircularProgress,
  Paper,
  Collapse,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "@mui/material";
import Page from "../components/Page";
import { ViewPage } from "../sections/@dashboard/viewCompany";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../core/redux/hooks";
import { useCallback, useEffect, useState } from "react";
import { request } from "../utils/Axios";
import { CompanyData } from "../core/types/company.types";
import ReviewContainer from "../sections/@dashboard/viewCompany/ViewAccordionForm";
import FullScreenDialogCompany from "../sections/@dashboard/company/CompanyModal";
import {
  CompanyStatsDatesChart,
  CompanyStatsCountyChart,
} from "../sections/@dashboard/company/CompanyStatsChart";
import ClearJobs from "../sections/@dashboard/company/ClearJobs";
import ViewCompanyJobs from "./ViewCompanyJobs";
import {
  selectCompanyJobs,
  selectJobStatus,
} from "../core/redux/features/jobs/jobSlice";
import { fetchJobsByCompany } from "../core/redux/features/jobs/jobThunks";
import MiniBlogCard from "./MiniBlogCard";
import { SubmitHandler, useForm } from "react-hook-form";
import { addBlogPost } from "../core/redux/features/companies/companyThunks";
import { ExpandMore } from "@mui/icons-material";
import { toastSuccess } from "../components/CustomToastify";
import UpdateRank from "../sections/@dashboard/company/UpdateRank";

export interface CompanyStaticsType {
  dates: { _id: string; count: number }[];
  events: { _id: string; count: number }[];
  sources: { _id: string; count: number }[];
  users: { _id: string; count: number }[];
}

export default function ViewCompany() {
  const { slug } = useParams();
  const [company, setCompany] = useState<CompanyData | null>(null);
  const companyJobs = useAppSelector(selectCompanyJobs);
  const [viewJobs, setViewJobs] = useState(false);
  const [viewRank, setViewRank] = useState(false);
  const [companyStats, setCompanyStats] = useState<CompanyStaticsType>();

  const jobsStatus = useAppSelector(selectJobStatus);

  // -------------------Redux------------------------
  const dispatch = useAppDispatch();
  // ------------------------------------------------

  const handleJobs = () => {
    setViewJobs((prev) => !prev);
  };

  const handleRank = () => {
    setViewRank((prev) => !prev);
  };

  const handleFetchCompanyJobs = useCallback(async () => {
    const { data } = await request.get(`api/v1/company/slug/${slug}`);
    setCompany(data.body);
    const dates = await request.get(
      `api/v1/company/stats/dates/${data.body._id}`
    );
    const events = await request.get(
      `api/v1/company/stats/events/${data.body._id}`
    );
    const sources = await request.get(
      `api/v1/company/stats/sources/${data.body._id}`
    );
    const users = await request.get(
      `api/v1/company/stats/users/${data.body._id}`
    );

    const newStatsObject = {
      dates: dates.data.body.dates,
      events: events.data.body.events,
      sources: sources.data.body.sources,
      users: users.data.body.users,
    };
    setCompanyStats(newStatsObject);

    dispatch(fetchJobsByCompany({ slug: slug as string, limit: 12, skip: 0 }));
  }, [slug]);

  useEffect(() => {
    if (slug) {
      handleFetchCompanyJobs();
    }
  }, [slug, dispatch, handleFetchCompanyJobs]);

  return (
    <Page title="Job Center">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4">View Company - {company?.name}</Typography>
          <Stack gap="8px" direction="row">
            {company && <FullScreenDialogCompany isEdit={true} {...company} />}

            <Button variant="contained" color="primary" onClick={handleJobs}>
              View Company Jobs
            </Button>

            {company ? (
              <UpdateRank name={company.name} id={company?._id} />
            ) : null}
            {company ? (
              <ClearJobs name={company.name} id={company?._id} />
            ) : null}
          </Stack>
        </Stack>
        {viewJobs ? (
          <>
            {jobsStatus === "loading" ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : (
              <ViewCompanyJobs jobs={companyJobs} />
            )}
          </>
        ) : (
          <>
            <Box>
              {company && (
                <ViewPage
                  companyStats={companyStats}
                  company={company}
                  jobs={company.numberOfJobs}
                />
              )}
              <Box
                mt={"20px"}
                sx={{
                  padding: {
                    xs: "40px 22px 20px 20px",
                    md: "40px 22px 40px 20px",
                  },
                  width: "100%",
                  bgcolor: "#fff",
                }}
              >
                <Typography component={"h3"} variant="h6">
                  Blog Posts
                </Typography>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>Add a new blog post</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AddBlogPostForm
                      companyId={company?._id}
                      setCompany={setCompany}
                    />
                  </AccordionDetails>
                </Accordion>

                <Divider sx={{ m: "20px 0" }} />

                <Typography component={"h3"} variant="h6">
                  All Blog Posts
                </Typography>

                <Grid
                  width={{ xs: "100%", sm: "100%", md: "100%" }}
                  mt={7}
                  container
                  columns={{ xs: 4, sm: 3, md: 3, lg: 3, xsl: 4, xl: 5 }}
                  justifyContent="center"
                >
                  {company && company?.blogPosts?.length > 0
                    ? company?.blogPosts.map((post, idx) => (
                        <Grid
                          key={idx}
                          item
                          xs={2}
                          sm={1}
                          md={1}
                          lg={1}
                          mt={2}
                          alignItems="center"
                          padding={1}
                        >
                          <MiniBlogCard
                            setCompany={setCompany}
                            link={post}
                            companyId={company._id}
                          />
                        </Grid>
                      ))
                    : null}
                </Grid>
              </Box>
              {company && <ReviewContainer company={company} />}
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              mt="40px"
              ml={1}
            >
              {/* <Typography variant="h4">Showing all jobs</Typography> */}
              {/* <FullScreenDialog /> */}
            </Stack>
            {/* <ProductList /> */}
            {companyStats?.dates ? (
              <Stack spacing={3} mb={3}>
                <CompanyStatsDatesChart
                  tagName="Events by Date"
                  title="Events by Date"
                  companyId={company?._id || ""}
                />
                <CompanyStatsCountyChart
                  title="Events by Country"
                  companyId={company?._id || ""}
                />
              </Stack>
            ) : null}
          </>
        )}
      </Container>
    </Page>
  );
}

const AddBlogPostForm = ({
  companyId,
  setCompany,
}: {
  companyId: string | undefined;
  setCompany: React.Dispatch<React.SetStateAction<CompanyData | null>>;
}) => {
  const dispatch = useAppDispatch();

  const { handleSubmit, register } = useForm<{ link: string }>();

  const onSubmit: SubmitHandler<{ link: string }> = (data) => {
    if (companyId) {
      dispatch(addBlogPost({ link: data.link, companyId })).then((res) => {
        setCompany(res.payload.body);
        toastSuccess("Post added successfully");
      });
    }
    return;
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={2}>
      <TextField
        {...register("link")}
        label="Source link"
        size="small"
        type="url"
        required
      />
      <Button type="submit" variant="contained">
        Save
      </Button>
    </Stack>
  );
};

// const dummyJobData: JobData[] = [
//   {
//     title: "Software Engineer",
//     jobUrl: "https://example.com/job/1",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     jobOverview: "Full-time Software Engineering position",
//     company: { _id: "1", logo: "company1_logo.png", name: "Tech Co." },
//     compensation: {
//       from: 60000,
//       to: 80000,
//       currency: "USD",
//       info: "Salary is negotiable based on experience.",
//     },
//     jobRoles: ["Software Development", "Programming"],
//     users: ["user1", "user2"],
//     location: { _id: "1", country: "United States" },
//     cityOrState: "San Francisco",
//     jobType: { _id: "1", title: "Full-time" },
//     experience: [{ _id: "1", title: "Mid-level" }],
//     jobFrequency: { _id: "1", title: "Permanent" },
//     createdAt: 1634494834,
//     applicants: 10,
//     category: { _id: "1", slug: "tech", title: "Technology" },
//     skills: [
//       { _id: "1", title: "JavaScript" },
//       { _id: "2", title: "Node.js" },
//     ],
//     reviews: [
//       { user: "user1", _id: "1", rate: 4, body: "Great company to work for!" },
//       {
//         user: "user2",
//         _id: "2",
//         rate: 5,
//         body: "Excellent team and projects.",
//       },
//     ],
//     rating: 4.5,
//     country: "United States",
//     city: "San Francisco",
//     state: "California",
//     cities: [
//       { city: "San Francisco", countryCode: "US", stateCode: "CA" },
//       { city: "New York", countryCode: "US", stateCode: "NY" },
//     ],
//     slug: "software-engineer",
//     _id: "job1",
//   },
//   {
//     title: "Product Manager",
//     jobUrl: "https://example.com/job/2",
//     description:
//       "Consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//     jobOverview: "Exciting opportunity for a Product Manager!",
//     company: { _id: "2", logo: "company2_logo.png", name: "Product Co." },
//     compensation: {
//       from: 80000,
//       to: 100000,
//       currency: "USD",
//       info: "Benefits include health insurance and stock options.",
//     },
//     jobRoles: ["Product Management", "Strategy"],
//     users: ["user3", "user4"],
//     location: { _id: "2", country: "United States" },
//     cityOrState: "New York",
//     jobType: { _id: "2", title: "Contract" },
//     experience: [{ _id: "2", title: "Senior" }],
//     jobFrequency: { _id: "2", title: "Temporary" },
//     createdAt: 1634494835,
//     applicants: 8,
//     category: { _id: "2", slug: "business", title: "Business" },
//     skills: [
//       { _id: "3", title: "Product Strategy" },
//       { _id: "4", title: "Market Research" },
//     ],
//     reviews: [
//       {
//         user: "user3",
//         _id: "3",
//         rate: 4.5,
//         body: "Great company culture and supportive team.",
//       },
//       {
//         user: "user4",
//         _id: "4",
//         rate: 4,
//         body: "Challenging projects with room for innovation.",
//       },
//     ],
//     rating: 4.25,
//     country: "United States",
//     city: "New York",
//     state: "New York",
//     cities: [
//       { city: "New York", countryCode: "US", stateCode: "NY" },
//       { city: "San Francisco", countryCode: "US", stateCode: "CA" },
//     ],
//     slug: "product-manager",
//     _id: "job2",
//   },
//   {
//     title: "Data Analyst",
//     jobUrl: "https://example.com/job/3",
//     description:
//       "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//     jobOverview: "Join our team as a Data Analyst!",
//     company: {
//       _id: "3",
//       logo: "company3_logo.png",
//       name: "Data Analytics Inc.",
//     },
//     compensation: {
//       from: 55000,
//       to: 70000,
//       currency: "USD",
//       info: "Flexible work hours and remote options available.",
//     },
//     jobRoles: ["Data Analysis", "Statistics"],
//     users: ["user5", "user6"],
//     location: { _id: "3", country: "United States" },
//     cityOrState: "Los Angeles",
//     jobType: { _id: "1", title: "Full-time" },
//     experience: [{ _id: "1", title: "Mid-level" }],
//     jobFrequency: { _id: "1", title: "Permanent" },
//     createdAt: 1634494836,
//     applicants: 12,
//     category: { _id: "3", slug: "tech", title: "Technology" },
//     skills: [
//       { _id: "5", title: "SQL" },
//       { _id: "6", title: "Excel" },
//     ],
//     reviews: [
//       {
//         user: "user5",
//         _id: "5",
//         rate: 4,
//         body: "Interesting projects and opportunities for skill development.",
//       },
//       {
//         user: "user6",
//         _id: "6",
//         rate: 5,
//         body: "Supportive team and great work environment.",
//       },
//     ],
//     rating: 4.5,
//     country: "United States",
//     city: "Los Angeles",
//     state: "California",
//     cities: [
//       { city: "Los Angeles", countryCode: "US", stateCode: "CA" },
//       { city: "San Francisco", countryCode: "US", stateCode: "CA" },
//     ],
//     slug: "data-analyst",
//     _id: "job3",
//   },
// ];
