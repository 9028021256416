import {
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
  CardHeader,
  Stack,
  Button,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { selectAuthStatus } from "../../core/redux/features/auth/authSlice";
import { fetchNewJobs } from "../../core/redux/features/jobs/jobThunks";
import {
  selectJobCategoriesStats,
  selectUserLocationStats,
  selectUserApplyStats,
  selectJobsByCompanies,
  selectJobTypeApplyStats,
  selectJobTypeViewStats,
} from "../../core/redux/features/stats/statsSlice";
import {
  fetchDashboard1Stats,
  fetchDashboard2Stats,
  fetchYearlyUserStats,
  fetchJobCategoriesStats,
  fetchUserLocationStats,
  fetchUserApplyStats,
  fetchJobByCompaniesStats,
  fetchApplyByJobTypeStats,
  fetchViewStatsByCountryAndJobType,
  fetchYearlyUserStatsByCountry,
  fetchUserEvents,
} from "../../core/redux/features/stats/statsThunks";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import {
  AppWebsiteVisits,
  AppCurrentVisits,
  AppConversionRates,
  AppCurrentSubject,
} from "../../sections/@dashboard/app";

import { TabPanel } from "../Payment";
import { customTheme } from "../../theme";
import { selectCompanies } from "../../core/redux/features/companies/companiesSlice";
import { fetchCompanies } from "../../core/redux/features/companies/companyThunks";
import { Link } from "react-router-dom";
import { ApexChart } from "../../sections/@dashboard/company/CompanyStatsChart";
import UsersByCountryChart from "../../sections/@dashboard/app/UsersByCountryChart";
import ClicksOnApplyNowByCountryChart from "../../sections/@dashboard/app/ClicksOnApplyNowByCountry";
import AppWebsiteViews from "../../sections/@dashboard/app/AppWebsiteViews";
import AppWebsiteApplications from "../../sections/@dashboard/app/AppWebsiteApplications";

type Props = {};

const typesArray = ["General Analytics", "Company Analytics"];

const firstChartButtons = [
  { name: "Visits", value: "visits" },
  { name: "Views", value: "views" },
  { name: "Applications", value: "applications" },
];

const Analytics = (props: Props) => {
  const [tabValue, setTabValue] = useState(0);
  const authStatus = useAppSelector(selectAuthStatus);
  const dispatch = useAppDispatch();
  const userApplyStats = useAppSelector(selectUserApplyStats);
  const [firstChartState, setFirstChartState] = useState("visits");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const companies = useAppSelector(selectCompanies);
  const jobCategorystats = useAppSelector(selectJobCategoriesStats);
  const userLocationsStats = useAppSelector(selectUserLocationStats);
  const jobTypeApplyStats = useAppSelector(selectJobTypeApplyStats);
  const jobTypeViewStats = useAppSelector(selectJobTypeViewStats);
  const jobsByCompanies = useAppSelector(selectJobsByCompanies);

  const handleFetchStats = useCallback(async () => {
    dispatch(fetchDashboard1Stats());
    dispatch(fetchDashboard2Stats());
    dispatch(fetchYearlyUserStats());
    dispatch(fetchJobCategoriesStats());
    dispatch(fetchUserLocationStats("all"));
    dispatch(fetchJobByCompaniesStats());
    dispatch(fetchNewJobs());
    dispatch(fetchCompanies());
    dispatch(fetchUserApplyStats());
    dispatch(fetchUserEvents());
    dispatch(fetchApplyByJobTypeStats());
    dispatch(
      fetchViewStatsByCountryAndJobType({
        timePeriod: "7-days",
        category: "62490bfff9c26249edf80664",
      })
    );
    dispatch(fetchYearlyUserStatsByCountry());
  }, [dispatch]);

  useEffect(() => {
    if (authStatus === "FETCH_SUCCESS" || authStatus === "LOGIN_SUCCESS") {
      handleFetchStats();
    }
  }, [handleFetchStats, authStatus]);

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        sx={{ marginBottom: 2 }}
      >
        {typesArray.map((item, idx) => (
          <Tab key={idx} label={item} />
        ))}
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack gap={2} flexDirection="row">
              {firstChartButtons.map((item) => (
                <Button
                  key={item.value}
                  onClick={() => setFirstChartState(item.value)}
                  variant="contained"
                  disabled={firstChartState === item.value}
                >
                  {item.name}
                </Button>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {(firstChartState === "visits" && <AppWebsiteVisits />) || null}
            {(firstChartState === "views" && <AppWebsiteViews />) || null}
            {(firstChartState === "applications" && (
              <AppWebsiteApplications />
            )) ||
              null}
          </Grid>

          <Grid item xs={12} md={6}>
            <AppCurrentVisits
              title="Jobs by Category"
              chartData={jobCategorystats.map((stat) => ({
                label: stat.category,
                value: stat.numbOfJobs,
              }))}
              chartColors={[
                customTheme.palette.primary.main,
                customTheme.palette.chart.blue[0],
                customTheme.palette.chart.violet[0],
                customTheme.palette.chart.yellow[0],
              ]}
              subheader={undefined}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppConversionRates
              periods={false}
              normalHeight={false}
              fetchData={undefined}
              defaultProjection="all"
              title="Users by countries"
              subheader="Top 10 countries by user"
              // chartData={userLocationsStats.totalUsers.map((stat) => ({
              //   label: stat.country,
              //   value: stat.totalUsers,
              // }))}
              chartData={userLocationsStats.totalUsers.map((stat) => ({
                label: stat._id,
                value: stat.count,
              }))}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <UsersByCountryChart />
          </Grid>

          <Grid item xs={12}>
            <ClicksOnApplyNowByCountryChart />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppConversionRates
              defaultProjection={undefined}
              periods
              normalHeight
              fetchData={fetchApplyByJobTypeStats}
              title={`Applications by job type`}
              subheader="Job types with their application rates"
              chartData={jobTypeApplyStats.map((stat) => ({
                label: stat.jobtype,
                value: stat.count,
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppConversionRates
              periods={false}
              normalHeight={false}
              fetchData={undefined}
              defaultProjection="all"
              title="Countries with the most number of jobs"
              subheader="10 countries with the most number of jobs"
              // chartData={userLocationsStats.totalJobs.map((stat) => ({
              //   label: stat.country,
              //   value: stat.totalJobs,
              // }))}
              chartData={userLocationsStats.totalJobs.map((stat) => ({
                label: stat._id,
                value: stat.count,
              }))}
            />
          </Grid>

          <Grid item xs={12} md={12} sx={{ height: "fit-content" }}>
            <AppCurrentSubject
              title="Jobs by Companies"
              chartLabels={jobsByCompanies.map((stat) => stat.company)}
              chartData={[
                {
                  name: "Series 1",
                  data: jobsByCompanies.map((stat) => stat.numbOfJobs),
                },
              ]}
              subheader={undefined}
              chartColors={undefined}
            />
          </Grid>

          <Grid item xs={12}>
            <ApexChart title={""} stats={jobTypeViewStats} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
          {companies.map((item) => (
            <Grid item xs={3}>
              <Link to={`${item.slug}`}>
                <Card
                  key={item._id}
                  sx={{
                    py: 5,
                    boxShadow: 2,
                    textAlign: "center",
                    // color: (theme) => theme.palette[color].darker,
                    bgcolor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    alignItems: "center",
                    ":hover": {
                      boxShadow:
                        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;",
                      cursor: "pointer",
                      transform: " scale(105%)",
                      transition: "1100ms",
                    },
                  }}
                >
                  <img
                    src={item.logo}
                    alt="company logo"
                    width="64px"
                    height="64px"
                  />
                  <Typography>{item.name}</Typography>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </TabPanel>
    </div>
  );
};

export default Analytics;
